import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Row } from 'config/types';

type Props = {
  rows: Row[];
};
export const PdfAuditTrailBody = ({ rows }: Props) => {
  // Create styles
  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      borderBottomColor: '#f6f6f6',
      borderBottomWidth: 1,
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '1.6px',
    },
    signedBy: {
      width: '33%',
      textAlign: 'left',
      borderRightColor: '#ffffff',
      borderRightWidth: 1,
      paddingRight: 8,
      paddingLeft: 8,
    },
    when: {
      width: '12%',
      borderRightColor: '#ffffff',
      borderRightWidth: 1,
      textAlign: 'left',
      paddingRight: 16,
    },
    where: {
      width: '30%',
      borderRightColor: '#ffffff',
      borderRightWidth: 1,
      textAlign: 'left',
      paddingRight: 8,
    },
    signature: {
      width: '25%',
      textAlign: 'right',
    },
    image: {
      width: 200,
      padding: 8,
    },
  });

  return (
    <View>
      {rows &&
        rows.length > 0 &&
        rows.map((row) => {
          return (
            <View style={styles.row}>
              <View style={styles.signedBy}>
                <View>
                  <Text>{row.name}</Text>
                </View>
                <View>
                  <Text>{row.email}</Text>
                </View>
              </View>
              <View style={styles.when}>
                <Text>{row.createTime}</Text>
              </View>
              <View style={styles.where}>
                <View>
                  <Text>IP: {row.json?.ip}</Text>
                </View>
                <View>
                  <Text>Location: {`${row.json.city}, ${row.json.country_name}`}</Text>
                </View>
                <View>
                  <Text>Timezone: {`${row.json.timezone}, ${row.json.utc_offset}`}</Text>
                </View>
              </View>
              <View style={styles.signature}>
                <Image style={styles.image} source={row.base64} />
              </View>
            </View>
          );
        })}
    </View>
  );
};
