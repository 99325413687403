import { ContractLink, ContractTypes, EventStatuses } from 'config/enums';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  pdfFileLoadSelector,
  setContract,
  setContractLink,
  setContractValue,
  setFillPlaceholderBefore,
  setIpInfo,
  setIsDone,
  setPdfFileLoad,
  setPlaceholders,
  setShareBlockReady,
} from 'slices/app-slice';
import { useGetContractByShareLinkQuery } from 'slices/contract-api-slice';
import { SignModal } from 'components/sign-modal/sign-modal';
import { Notification } from 'components/notification/notification';
import { SharePageBlock } from 'components/share-page-block/share-page-block';
import { ApproveModal } from 'components/approve-modal/approve-modal';
import {
  useCreateContractEventMutation,
  useGetContractEventsByShareLinkQuery,
} from 'slices/contract-event-api-slice';
import { ResultModal } from 'components/result-modal/result-modal';
import axios from 'axios';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
import { useGetContractSignsByShareLinkQuery } from 'slices/contract-sign-api-slice';
import { addActualColors, delColorFromHtml } from 'utils/data-helper';

export const SharePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const { setArrayBuffer, clearArrayBuffer } = useSaveArrayBuffer();
  const sharingLinkUID = history.location.pathname.split('/sharing/')[1];

  const [createEvent] = useCreateContractEventMutation();
  const { data: contractData } = useGetContractByShareLinkQuery(sharingLinkUID);

  const { data: contractEventData } = useGetContractEventsByShareLinkQuery(
    { shareLink: sharingLinkUID },
    {
      skip:
        contractData && contractData.shareLink && contractData.audit
          ? false
          : true,
    }
  );
  const { data: contractSignsData } = useGetContractSignsByShareLinkQuery(
    sharingLinkUID,
    {
      skip: contractData && contractData.shareLink ? false : true,
    }
  );
  useEffect(() => {
    if (contractData && contractData.contractValue) {
      let value =
        contractData.contractType.toString() === ContractTypes.PDF.toString()
          ? contractData.contractValue
          : delColorFromHtml(contractData.contractValue);
      if (
        contractData.contractType.toString() !== ContractTypes.PDF.toString() &&
        !contractData.isDone
      ) {
        value = addActualColors(
          contractData.audit,
          contractData.shareLink,
          value,
          contractData.placeholders
        );
      }
      dispatch(
        setContract({
          shareLink: contractData.shareLink,
          createTime: contractData.createTime,
          changeTime: contractData.changeTime,
          contractValue: value,
          statusId: contractData.statusId,
          statusName: contractData.statusName,
          view: contractData.view,
          contractType: contractData.contractType,
          contractName: contractData.contractName,
          fullname: contractData.fullname,
          email: contractData.email,
          audit: contractData.audit,
          controlLink: contractData.controlLink,
        })
      );
      dispatch(
        setContractValue({
          changeTime: contractData.changeTime,
          contractValue: value,
        })
      );
      dispatch(setIsDone(contractData.isDone));
      // console.log('10');
      if (!contractData.isDone && contractData.audit) {
        // console.log('contractData', contractData);
        dispatch(setIsDone(true));
      }
      if (!contractData.isDone && !contractData.audit) {
        getSignIPInfo();
        if (contractData.placeholders && contractData.placeholders.length > 0) {
          dispatch(setPlaceholders(contractData.placeholders));
          dispatch(setFillPlaceholderBefore(true));
        }
      }
      if (
        contractData.contractType.toString() === ContractTypes.PDF.toString()
      ) {
        clearArrayBuffer();
        axios
          .get(contractData.contractValue, {
            responseType: 'arraybuffer',
          })
          .then(async function (response) {
            // debugger;
            // dispatch(setPdfFile(response.data));
            await setArrayBuffer('pdfFile', response.data);
            await setArrayBuffer('pdfFileOriginal', response.data);
            dispatch(setPdfFileLoad(pdfFileLoad + 1));
            dispatch(setShareBlockReady(true));
          });
        if (contractData.isDone || contractData.audit) {
          if (
            contractData.placeholders &&
            contractData.placeholders.length > 0
          ) {
            dispatch(setPlaceholders(contractData.placeholders));
          }
        }
      } else {
        dispatch(setShareBlockReady(true));
      }
      dispatch(setContractLink(ContractLink.SHARE));
      if (!contractData.audit) {
        createEvent({
          shareLink: contractData.shareLink,
          status: EventStatuses.SEEN,
        });
      }
    }
  }, [contractData]);
  const getSignIPInfo = async () => {
    // debugger;
    await axios
      .get('https://ipapi.co/json', {
        responseType: 'json',
      })
      .then((payload: any) => {
        const ipInfoStr = JSON.stringify(payload.data);
        dispatch(setIpInfo(ipInfoStr));
      });
  };
  return (
    <>
      <SharePageBlock />
      <SignModal />
      <ApproveModal />
      <ResultModal />
      <Notification />
    </>
  );
};
