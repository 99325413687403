/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Typography, Table, Tag, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';
import {
  chooseWebhookSelector,
  currentClientSelector,
  setChooseWebhook,
  setPayloadModal,
  setWebhookModal,
} from 'slices/app-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetWebhookByWebhookKeyQuery } from 'slices/webhook-api-slice';
import { Webhook } from 'config/types';
import { WebhookCreateModal } from 'components/webhook-create-modal/webhook-create-modal';
import { WebhookPayloadModal } from 'components/webhook-payload-modal/webhook-payload-modal';
import { useGetWebhookEventsQuery } from 'slices/webhook-event-api-slice';
import { useGetEventStatusQuery } from 'slices/common-api-slice';

const { Text, Link } = Typography;

interface DataType {
  key?: React.Key;
  event?: string;
  mid?: string;
  status?: string;
  created?: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Event',
    dataIndex: 'event',
  },
  {
    title: 'MessageID',
    dataIndex: 'mid',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Created at',
    dataIndex: 'created',
  },
];

export const WebhookPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentClient = useSelector(currentClientSelector);
  const chooseWebhook = useSelector(chooseWebhookSelector);
  const [currWebhook, setCurrWebhook] = useState<Webhook>({});
  const [events, setEvents] = useState<DataType[]>([]);
  const [eventsNumber, setEventsNumber] = useState(0);
  const webhookUID = history.location.pathname.split('/webhook/')[1];

  const { data: webhookData } = useGetWebhookByWebhookKeyQuery(
    {
      webhookKey: webhookUID,
      clientKey: currentClient.clientKey,
    },
    {
      skip:
        chooseWebhook && chooseWebhook.webhookKey && currentClient.clientKey
          ? false
          : true,
    }
  );
  const { data: webhookEventsData } = useGetWebhookEventsQuery(
    {
      webhookKey: webhookUID,
      clientKey: currentClient.clientKey,
    },
    {
      skip: !currentClient.clientKey ? true : false,
    }
  );
  const { data: eventStatusData } = useGetEventStatusQuery();

  useEffect(() => {
    if (chooseWebhook && chooseWebhook.webhookKey) {
      setCurrWebhook(chooseWebhook);
      let number = 0;
      for (let prop in chooseWebhook) {
        switch (prop) {
          case 'created':
          case 'approved':
          case 'seen':
          case 'sent':
          case 'signed':
            if (chooseWebhook[prop]) {
              number += 1;
            }
            break;
        }
      }
      setEventsNumber(number);
    }
  }, [chooseWebhook]);
  useEffect(() => {
    if (webhookData) {
      setCurrWebhook(webhookData);
      dispatch(setChooseWebhook(webhookData));
      let number = 0;
      for (let prop in webhookData) {
        switch (prop) {
          case 'created':
          case 'approved':
          case 'seen':
          case 'sent':
          case 'signed':
            if (webhookData[prop]) {
              number += 1;
            }
            break;
        }
      }
      setEventsNumber(number);
    }
  }, [webhookData]);
  useEffect(() => {
    if (webhookEventsData && webhookEventsData.length > 0) {
      let eventsTmp: DataType[] = [];
      webhookEventsData?.forEach((webhookEventData) => {
        const statusFind = eventStatusData.find(
          (eventStatus) =>
            eventStatus.id.toString() === webhookEventData.statusId.toString()
        );
        eventsTmp.push({
          key: webhookEventData.id,
          event: `contract.${statusFind.name.toLowerCase()}`,
          mid: webhookEventData.messageKey,
          status: 'Failed',
          created: dayjs(webhookEventData.createTime).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
        });
      });
      setEvents(eventsTmp);
    }
  }, [webhookEventsData]);

  return (
    <>
      <Space direction="vertical" style={{ display: 'flex' }} size={32}>
        <Space direction="vertical">
          <Space>
            <Text type="secondary">Webhook</Text>
            <Link
              onClick={() => {
                dispatch(setWebhookModal(true));
              }}
            >
              Edit
            </Link>
            <Link
              style={{ display: 'none' }}
              onClick={() => {
                dispatch(setPayloadModal(true));
              }}
            >
              Payload
            </Link>
          </Space>
          <Tag color="volcano">{currWebhook.url}</Tag>
        </Space>
        <Space size={32}>
          <Space direction="vertical">
            <Text type="secondary">Status</Text>
            <Tag>Active</Tag>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">Listening</Text>
            <Tag>{`${eventsNumber} events`}</Tag>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">Created</Text>
            <Tag>
              {currWebhook.createTime
                ? dayjs(currWebhook.createTime).format('YYYY-MM-DD HH:mm:ss')
                : dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}
            </Tag>
          </Space>
          <Space direction="vertical">
            <Text type="secondary">Signing secret</Text>
            <Tag>{currWebhook.secret}</Tag>
          </Space>
        </Space>
        <Table columns={columns} dataSource={events} />
      </Space>
      <WebhookPayloadModal />
      <WebhookCreateModal />
    </>
  );
};
